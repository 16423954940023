import React from 'react'
import ListingsContainer from '../ListingsContainer'
import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useDeleteListing } from '../../data/hooks/listings'
import { useAuth } from '../../context/auth'
import { useGetExpiredListings } from '../../data/hooks/listings'


function ExpiredListings() {
  const { user } = useAuth()  
  const {mutateAsync: deleteListing } = useDeleteListing()
  const {data: ExpiredListings, isLoading} = useGetExpiredListings(user.uid)

  return (
    <Box
      sx={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 3 }}>
        Expired Listings
      </Typography>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <ListingsContainer
          listings={ExpiredListings}
          handleDelete={deleteListing}
          user={user}
        />
      )}
    </Box>
  )
}

export default ExpiredListings
