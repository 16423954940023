import {
    useQuery,
    useMutation, 
    useQueryClient
} from '@tanstack/react-query';
import { getUser } from '../operations/queries/user';
import { logout } from '../operations/mutations/user';


const userKey = ['userQuery']

export function useGetUser(userId) {
    return useQuery({
        queryKey: [userKey],
        queryFn: () => getUser(userId),
        enabled: !!userId,
    })
}

export function useLogout() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => logout(),
        onSuccess: () => {
            queryClient.clear()
            
        },
    })
}