import React from 'react'
import {
  Box,
  Chip,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
} from '@mui/material'
import BulletList from '../BulletList'
import { useNavigate } from 'react-router-dom'
import { createListingCheckoutSession } from '../../firebase'
import { useAuth } from '../../context/auth'
import { getCategoryIcon } from '../CategoryDropdown'
import { getStarIcons } from '../../helpers/starRating'

// Hard-coded "Seller Info" placeholders
const SELLER_INFO = {
  name: 'John Smith',
  timesSold: 25,
  rating: 4.5, // out of 5
}

function ListingDetailCard({ listing }) {
  const navigate = useNavigate()
  const { user } = useAuth()

  const handleBuy = async () => {
    if (!user || !user?.role) {
      // If user isn't logged in or doesn't have a role, maybe navigate home or login
      navigate('/')
      return
    }
    // Create Stripe Checkout session
    const sessionUrl = await createListingCheckoutSession({
      listingId: listing.id,
      listingUserId: listing.userId, // The vendor's user ID
      buyerId: user.uid,             // The logged-in buyer's user ID
    })
    // Redirect to Stripe
    window.location.href = sessionUrl
  }

  // Format category name (like your `formatCategory` function)
  function formatCategory(str) {
    if (!str) return ''
    return str
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/\b[a-z]/g, (char) => char.toUpperCase())
  }

  const Icon = getCategoryIcon(listing?.category)
  const formattedCategory = formatCategory(listing?.category)

  return (
    <Card sx={{ boxShadow: 15, mb: 3, border: 2, maxWidth: 600, margin: 'auto' }}>
      <CardContent>
        {/* Top Row: Icon, Limit, Category, Expiration */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          {/* Category Icon */}
          {Icon && <Icon />}

          {/* Quantity / Limit */}
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {listing?.quantity ? `Limit: ${listing?.quantity}` : 'Unlimited'}
          </Typography>

          {/* Category Chip */}
          {listing?.category && (
            <Chip
              label={formattedCategory}
              size="small"
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontSize: '.8rem',
                borderRadius: '10px',
              })}
            />
          )}

          {/* Expiration Date */}
          <Chip
            sx={{ fontSize: '.7.5rem' }}
            label={`expiring ${listing?.expirationDate}`}
          />
        </Box>

        {/* Title */}
        <Typography
          variant="h5"
          sx={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
            mt: 1,
          }}
        >
          {listing?.title}
        </Typography>

        {/* Price + Seller Name from listing (optional) */}
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 1 }}>
          <Chip
            label={`$${listing?.price}`}
            color="success"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              fontSize: '1rem',
            }}
          />
          {listing?.name && (
            <>
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ fontSize: '1rem', fontWeight: 'bold' }}
              >
                -
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                sx={{ fontSize: '1rem', fontWeight: 'bold' }}
              >
                {listing?.name}
              </Typography>
            </>
          )}
        </Box>

        {/* Bullets describing the listing */}
        <BulletList listOfBullets={[listing?.bullet1, listing?.bullet2, listing?.bullet3, listing?.bullet4]} />

        {/* New: Seller/Vendor Info Section (placeholder data) */}
        <Box
          sx={{
            mt: 2,
            p: 2,
            backgroundColor: '#f5f5f5',
            borderRadius: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            About the Seller
          </Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            <strong>Name:</strong> {SELLER_INFO.name}
          </Typography>
          <Typography variant="body2">
            <strong>Times Sold:</strong> {SELLER_INFO.timesSold}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
            <Typography variant="body2">
            <strong>Rating:</strong> 
            </Typography>
            {getStarIcons(SELLER_INFO.rating)}
          </Box>
        </Box>
      </CardContent>

      {/* Bottom: Single Buy Button */}
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button variant="contained" size="small" onClick={handleBuy}>
          Buy
        </Button>
      </CardActions>
    </Card>
  )
}

export default ListingDetailCard
