import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Chip,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
} from '@mui/material'
import BulletList from '../BulletList'
import { getCategoryIcon } from '../CategoryDropdown'
import { useDeleteListing } from '../../data/hooks/listings'

function ListingCard({ listing, user }) {
  const {mutateAsync: deleteListing } = useDeleteListing()
  const navigate = useNavigate()

  const handleEdit = () => {
    navigate(`/edit/${listing.id}`)
  }

  const handleDetails = () => {
    navigate(`/view/${listing.userId}/${listing.id}`)
  }

  function formatCategory(str) {
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .toLowerCase() // Convert the string to lowercase
      .replace(/\b[a-z]/g, (char) => char.toUpperCase()) // Convert the first character of each word to uppercase
  }

  const {
    title,
    price,
    expirationDate,
    quantity,
    bullet1,
    bullet2,
    bullet3,
    bullet4,
    category,
    name,
  } = listing

  const formattedCategory = formatCategory(category)
  const Icon = getCategoryIcon(category)
  return (
    <Card sx={{ boxShadow: 15, mb: 3, border: 2 }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Icon />
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {quantity ? `Limit: ${quantity}` : 'Unlimited'}
          </Typography>
          {category && (
            <Chip
              label={formattedCategory}
              size="small"
              sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                fontSize: '.8rem',
                borderRadius: '10px',
              })}
            />
          )}
          <Chip
            sx={{ fontSize: '.7.5rem' }}
            label={`expiring ${expirationDate}`}
          />
        </Box>
        <Typography
          variant="h5"
          sx={{
            fontSize: '1.7rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
          }}
        >
          <Chip
            label={`$${price}`}
            color="success" // This makes the chip green
            sx={{ color: 'white', fontWeight: 'bold', fontSize: '1rem' }} // Additional styling can be applied as needed
          />
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            -
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            {name}
          </Typography>
        </Box>
        {user?.role === 'SELLER' ? (
          <BulletList listOfBullets={[bullet1, bullet2, bullet3, bullet4]} />
        ) : (
          <BulletList listOfBullets={[bullet1]} />
        )}
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {user?.role === 'SELLER' ? (
          <>
            <Button
              variant="contained"
              size="small"
              color="tertiary"
              onClick={handleEdit}
            >
              <Typography variant="button>">Edit</Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => deleteListing(listing)}
            >
              <Typography variant="button>">Delete</Typography>
            </Button>
          </>
        ) : (
          <>
            {/* <Button variant="contained" size="small" >Buy</Button> */}
            <Button variant="contained" size="small" onClick={handleDetails}>
              Details
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  )
}

export default ListingCard
