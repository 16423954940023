import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { getNonExpiredListings } from '../operations/queries/listings';
import { deleteListing, createListing, updateListing } from '../operations/mutations/listings';
import { getSingleListing, getSoldListings, getExpiredListings } from '../operations/queries/listings';

export function useGetNonExpiredListings({ userId, category }) {
    return useQuery({
    // Include both `userId` and `category` in the key so
    // React Query knows to refetch when they change
        queryKey: ['nonExpiredListings', userId, category],
        queryFn: () => getNonExpiredListings({ userId, category })
    })
}

export function useDeleteListing() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (listing) => {
        // Perform the actual delete operation
        await deleteListing(listing)
    },
    onSuccess: () => {
      // Once delete is successful, invalidate or refetch
        queryClient.invalidateQueries(['listings'])
      // or: queryClient.refetchQueries(['listings', userId])
    },
    })
}

export function useGetSingleListing(userId, listingId) {
    return useQuery({
        queryKey: ['singleListing', userId, listingId],
        queryFn: () => getSingleListing(userId, listingId)
    })
}

export function useCreateListing () {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (listing) => {
            console.log('listing:', listing)
            // Perform the actual create operation
            await createListing(listing)
        },
        onSuccess: () => {
            // Once create is successful, invalidate or refetch
            queryClient.invalidateQueries(['listings'])
            // or: queryClient.refetchQueries(['listings', userId])
        },
    })
}

export function useUpdateListing() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: async (listing) => {
            // Perform the actual update operation
            await updateListing(listing)
        },
        onSuccess: () => {
            // Once update is successful, invalidate or refetch
            queryClient.invalidateQueries(['listings'])
            // or: queryClient.refetchQueries(['listings', userId])
        },
    })
}

export function useGetSoldListings(userId) {
    return useQuery({
        queryKey: ['soldListings', userId],
        queryFn: () => getSoldListings(userId)
    })
}

export function useGetExpiredListings(userId) {
    return useQuery({
        queryKey: ['expiredListings', userId],
        queryFn: () => getExpiredListings(userId)
    })
}