import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../firebaseDbConfig'

const deleteListingCloudFn = httpsCallable(functions, 'deleteListing')
const createListingCloudFn = httpsCallable(functions, 'createListing')
const updateListingCloudFn = httpsCallable(functions, 'updateListing')

// this deletes the listing from stripe as well.
export const deleteListing = async (obj) => {
    await deleteListingCloudFn({ listing: obj })
}

export const createListing = async (obj) => {
    await createListingCloudFn({ listing: obj })
}

export const updateListing = async (obj) => {
    await updateListingCloudFn({ listing: obj })
}