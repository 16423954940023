import { useEffect } from 'react'
import { Box, Button, TextField, Typography, Collapse } from '@mui/material'
import React, { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CategoryDropdown from '../CategoryDropdown'
import { useAuth } from '../../context/auth'
import { useCreateListing } from '../../data/hooks/listings'

const CreateListingPage = () => {
  const { user } = useAuth()  
  const { mutateAsync: createListing } = useCreateListing()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [price, setPrice] = useState(0)
  const [expirationDate, setExpirationDate] = useState('')
  const [expirationToggle, setExpirationToggle] = useState(true)
  const [quantity, setQuantity] = useState('')
  const [quantityToggle, setQuantityToggle] = useState(false)
  const [bullet1, setBullet1] = useState('')
  const [bullet2, setBullet2] = useState('')
  const [bullet3, setBullet3] = useState('')
  const [bullet4, setBullet4] = useState('')
  const [category, setCategory] = useState('')
  const [priceWithCharge, setPriceWithCharge] = useState(0)

  useEffect(() => {
    const serviceCharge = 0.15 // 15% service charge
    const calculatedPrice = price > 0 ? price * (1 + serviceCharge) : 0
    setPriceWithCharge(calculatedPrice.toFixed(2)) // Updating the price with service charge state
  }, [price]) // This effect depends on the price state

  const handleChangeCategory = (e, value) => {
    setCategory(value.value)
  }

  const handleChange = (e, type) => {
    switch (type) {
      case 'title':
        setTitle(e)
        break
      case 'price':
        setPrice(e)
        break
      case 'quantity':
        setQuantity(e)
        break
      case 'bullet1':
        setBullet1(e)
        break
      case 'bullet2':
        setBullet2(e)
        break
      case 'bullet3':
        setBullet3(e)
        break
      case 'bullet4':
        setBullet4(e)
        break
      default:
        return
    }
  }

  const handleChecked = (e, type) => {
    switch (type) {
      case 'expiration':
        if (!quantityToggle && !e) {
          return
        } else {
          setExpirationToggle(e)
        }
        break
      case 'quantity':
        if (!expirationToggle && !e) {
          return
        } else {
          setQuantityToggle(e)
        }
        break
      default:
        return
    }
  }

  const handleDateChange = (newValue) => {
    const date = dayjs(newValue).format('MM/DD/YYYY')
    setExpirationDate(date)
  }

  const [errorMessages, setErrorMessages] = useState({
    title: '',
    price: '',
    quantity: '',
    bullet1: '',
    bullet2: '',
    bullet3: '',
    bullet4: '',
    category: '',
  })

  const validateFields = () => {
    const errors = {}
    if (title.trim() === '') errors.title = 'Title is required'
    if (price <= 0) errors.price = 'Price must be greater than 0'
    if (quantityToggle && quantity.trim() === '')
      errors.quantity = 'Quantity is required'
    if (bullet1.trim() === '') errors.bullet1 = 'Bullet 1 is required'
    if (bullet2.trim() === '') errors.bullet2 = 'Bullet 2 is required'
    if (bullet3.trim() === '') errors.bullet3 = 'Bullet 3 is required'
    if (bullet4.trim() === '') errors.bullet4 = 'Bullet 4 is required'
    if (category.trim() === '') errors.category = 'Category is required'
    setErrorMessages(errors)
    return Object.keys(errors).length === 0 // returns true if no errors
  }

  const handleCreate = async () => {
    if (!validateFields()) return
    await createListing({
      title,
      price: priceWithCharge,
      originalPrice: price,
      expirationDate,
      expirationDateUnix: dayjs(expirationDate).unix(),
      quantity,
      userId: user.uid,
      bullet1,
      bullet2,
      bullet3,
      bullet4,
      longitude: user.longitude,
      latitude: user.latitude,
      category,
      name: user.name,
    })
    navigate('/')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '600px', // This can be any value you find suitable
          width: '100%', // Make it responsive by allowing it to take the full width on smaller screens
        }}
      >
        <Box
          sx={{
            p: 3,
            maxWidth: '500px',
          }}
        >
          <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
            Create Listing
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              size="small"
              label="Service Title"
              sx={{ mb: 2, boxShadow: 5, borderRadius: 1 }}
              onChange={(e) => {
                handleChange(e.target.value, 'title')
              }}
              error={errorMessages.title}
            />
            <TextField
              size="small"
              type={'number'}
              label="Price $"
              sx={{ boxShadow: 5, borderRadius: 1 }}
              onChange={(e) => {
                handleChange(e.target.value, 'price')
              }}
              error={errorMessages.price}
            />
            <Collapse
              in={price > 0}
              sx={{
                mb: 3,
              }}
            >
              <Typography variant="body2" sx={{ mt: 2, mb: 3, color: 'gray' }}>
                App Display Price:
                <Typography
                  component="span"
                  sx={{
                    color: 'green',
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                  }}
                >
                  {` $${priceWithCharge} `}
                </Typography>
                (includes a 15% service charge for our services, enhancing your
                selling experience with hassle-free transactions and support)
              </Typography>
            </Collapse>
            <Box
              sx={{
                border: 1,
                p: 1,
                borderRadius: 2,
                mb: 2,
                borderColor: 'lightGray',
                boxShadow: 5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  mb: 2,
                }}
              >
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Expiration"
                  checked={expirationToggle}
                  onChange={(e) =>
                    handleChecked(e.target.checked, 'expiration')
                  }
                />
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label="Quantity"
                  checked={quantityToggle}
                  onChange={(e) => handleChecked(e.target.checked, 'quantity')}
                />
              </Box>
              {expirationToggle && (
                <Box
                  sx={{
                    mb: 2,
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                      label="Sale expiration"
                      inputFormat="MM/DD/YYYY"
                      value={expirationDate}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField {...params} size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}
              {quantityToggle && (
                <TextField
                  size="small"
                  label="Quantity"
                  sx={{ mb: 2 }}
                  onChange={(e) => {
                    handleChange(e.target.value, 'quantity')
                  }}
                />
              )}
            </Box>
            <CategoryDropdown
              onChange={handleChangeCategory}
              title="Category"
              error={errorMessages.category}
            />
            <Box
              sx={{
                border: '1px solid lightGray',
                borderRadius: 1,
                pt: 1,
                pb: 1,
                p: 1,
                mb: 2,
                boxShadow: 5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ mb: 1 }}>Bullet point description</Typography>
              </Box>
              <TextField
                size="small"
                label="Bullet 1"
                sx={{ mb: 2, borderRadius: 1, width: '100%' }}
                onChange={(e) => {
                  handleChange(e.target.value, 'bullet1')
                }}
                error={errorMessages.bullet1}
              />
              <TextField
                size="small"
                label="Bullet 2"
                sx={{ mb: 2, borderRadius: 1, width: '100%' }}
                onChange={(e) => {
                  handleChange(e.target.value, 'bullet2')
                }}
                error={errorMessages.bullet2}
              />
              <TextField
                size="small"
                label="Bullet 3"
                sx={{ mb: 2, borderRadius: 1, width: '100%' }}
                onChange={(e) => {
                  handleChange(e.target.value, 'bullet3')
                }}
                error={errorMessages.bullet3}
              />
              <TextField
                size="small"
                label="Bullet 4"
                sx={{ mb: 2, borderRadius: 1, width: '100%' }}
                onChange={(e) => {
                  handleChange(e.target.value, 'bullet4')
                }}
                error={errorMessages.bullet4}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button variant="contained" onClick={handleCreate}>
              Create Listing
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CreateListingPage
