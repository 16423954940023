import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { sendPasswordReset } from '../../firebase'
import { Box, TextField, Button } from '@mui/material'
import { auth } from '../../firebaseDbConfig'

function Reset() {
  const [email, setEmail] = useState('')
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) return
    if (user) navigate('/dashboard')
  }, [user, loading])
  return (
    <Box
      sx={{
        m: 5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TextField
        sx={{
          mb: 2,
        }}
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-mail Address"
      />
      <Button
        sx={{
          mb: 2,
        }}
        variant="contained"
        className="reset__btn"
        onClick={() => sendPasswordReset(email)}
      >
        Send password reset email
      </Button>
      <Box
        sx={{
          mb: 3,
        }}
      >
        Don't have an account? <Link to="/register">Register</Link> now.
      </Box>
      <Box>
        Already have an account? <Link to="/login">Login</Link> now.
      </Box>
    </Box>
  )
}
export default Reset
