// starUtils.js (or in the same file if you prefer)
import React from 'react'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarBorderIcon from '@mui/icons-material/StarBorder'

/**
 * Given a rating from 0–5, returns an array of StarIcons:
 * e.g. 4.5 => [Star, Star, Star, Star, StarHalf]
 */
export function getStarIcons(rating) {
  // Ensure rating is within 0–5 just in case
  const safeRating = Math.min(5, Math.max(0, rating))
  
  const fullStars = Math.floor(safeRating)
  const hasHalfStar = (safeRating - fullStars) >= 0.5
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0)

  const icons = []

  // Full stars
  for (let i = 0; i < fullStars; i++) {
    icons.push(<StarIcon key={`full-${i}`} sx={{ color: '#f5c518' }} />)
  }

  // Half star
  if (hasHalfStar) {
    icons.push(<StarHalfIcon key="half" sx={{ color: '#f5c518' }} />)
  }

  // Empty stars
  for (let i = 0; i < emptyStars; i++) {
    icons.push(<StarBorderIcon key={`empty-${i}`} sx={{ color: '#f5c518' }} />)
  }

  return icons
}
