import MainLayout from './feature/MainLayout/index'
import routes from './utils/routes/index'
import { AuthProvider } from './context/auth'

function App() {
  return (
    <AuthProvider>
      <MainLayout routes={routes} />
    </AuthProvider>
  )
}

export default App
