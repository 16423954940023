import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import GrassIcon from '@mui/icons-material/Grass';
import PoolIcon from '@mui/icons-material/Pool';
import PetsIcon from '@mui/icons-material/Pets';
import HouseIcon from '@mui/icons-material/House';
import HandymanIcon from '@mui/icons-material/Handyman';
import SecurityIcon from '@mui/icons-material/Security';
import SevereColdIcon from '@mui/icons-material/SevereCold';
import YardIcon from '@mui/icons-material/Yard';
import PestControlIcon from '@mui/icons-material/PestControl';
import ChairIcon from '@mui/icons-material/Chair';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CountertopsIcon from '@mui/icons-material/Countertops';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import AddTaskIcon from '@mui/icons-material/AddTask';

const CategoryDropdown = ({ onChange, title, defaultValue, error }) => {

  const handleOnChange = (event, newValue) => {
    onChange(event, newValue)

    // Blur workaround
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
  }

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={categories}
      sx={{ width: '100%', mb: 2 }}
      defaultValue={categories.find(
        (category) => category.value === defaultValue
      )}
      renderInput={(params) => (
        <TextField size="small" {...params} label={title} error={error} />
      )}
      onChange={handleOnChange}
      getOptionLabel={(option) => option.label || ''}
      renderOption={(props, option) => (
        <li {...props}>
          {option.Icon && <option.Icon style={{ marginRight: 8 }} />}
          {option.label}
        </li>
      )}
    />
  )
}


export default CategoryDropdown

const categories = [
  { label: 'Lawn Care', value: 'lawn_care', Icon: GrassIcon },
  { label: 'Pool Maintenance', value: 'pool_maintenance', Icon: PoolIcon },
  { label: 'Pet Care', value: 'pet_care', Icon: PetsIcon },
  { label: 'House Cleaning', value: 'house_cleaning', Icon: HouseIcon },
  { label: 'Handyman Services', value: 'handyman_services', Icon: HandymanIcon },
  { label: 'Home Security Services', value: 'home_security_services', Icon: SecurityIcon },
  { label: 'Snow Removal', value: 'snow_removal', Icon: SevereColdIcon },
  { label: 'Gardening', value: 'gardening', Icon: YardIcon },
  { label: 'Pest Control', value: 'pest_control', Icon: PestControlIcon },
  { label: 'Holiday Decor', value: 'holiday_decor', Icon: ChairIcon },
  { label: 'Seasonal Check Ups', value: 'seasonal_check_ups', Icon: DownloadDoneIcon },
  { label: 'Household Upkeep Chores', value: 'household_upkeep_chores', Icon: CountertopsIcon },
  { label: 'Non-medical Home Care Services', value: 'non_medical_home_care', Icon: CleaningServicesIcon },
  { label: 'Transportation/Errands', value: 'transportation_errands', Icon: DirectionsCarIcon },
  { label: 'Car Care', value: 'car_care', Icon: NoCrashIcon },
  { label: 'Tasks', value: 'tasks', Icon: AddTaskIcon },
]

export const getCategoryIcon = (categoryLabel) => {
  const iconMap = {
    lawn_care: GrassIcon,
    pool_maintenance: PoolIcon,
    pet_care: PetsIcon,
    house_cleaning: HouseIcon,
    handyman_services: HandymanIcon,
    home_security_services: SecurityIcon,
    snow_removal: SevereColdIcon,
    gardening: YardIcon,
    pest_control: PestControlIcon,
    holiday_decor: ChairIcon,
    seasonal_check_ups: DownloadDoneIcon,
    household_upkeep_chores: CountertopsIcon,
    non_medical_home_care: CleaningServicesIcon,
    transportation_errands: DirectionsCarIcon,
    car_care: NoCrashIcon,
    tasks: AddTaskIcon,
  };

  return iconMap[categoryLabel] || null;
};