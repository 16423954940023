// queries/listings.js
import {
    query,
    getDocs,
    collection,
    collectionGroup,
    doc,
    where,
    orderBy,
    getDoc,
  } from 'firebase/firestore'
  import { db } from '../../../firebaseDbConfig'
  import dayjs from 'dayjs'
  
  export async function getNonExpiredListings({ userId, category }) {
    const todaysDate = dayjs().unix()
    // Decide the "base" collection reference
    let baseRef
    if (userId) {
      // For listings under a specific user (seller)
      const userDocRef = doc(db, 'users', userId)
      baseRef = collection(userDocRef, 'Listings')
    } else {
      // For listings across *all* users
      baseRef = collectionGroup(db, 'Listings')
    }
    // Build up Firestore constraints
    const constraints = [
      where('expirationDateUnix', '>=', todaysDate),
      orderBy('expirationDateUnix', 'desc'),
    ]
    if (category) {
      constraints.push(where('category', '==', category))
    }
    // Build the final Firestore query
    const q = query(baseRef, ...constraints)
    const snapshot = await getDocs(q)
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
  }
  

  export  const getSingleListing = async (userId, listingId) => {
    const ref = doc(db, 'users', userId)
    const colRef = doc(collection(ref, 'Listings'), listingId)
    try {
      const docSnapshot = await getDoc(colRef)
      if (docSnapshot.exists()) {
        const listingData = docSnapshot.data()
        // Add the document ID to the listing data
        listingData.id = docSnapshot.id
        return listingData
      } else {
        console.log(`No listing found with ID: ${listingId}`)
        return null
      }
    } catch (err) {
      console.log(err)
    }
  }

  export const getSoldListings = async (userId) => {
    console.log('userId:', userId)
    const ref = doc(db, 'users', userId)
    const colRef = collection(ref, 'PurchasedListings')
    try {
      const q = query(colRef)
      const docs = await getDocs(q)
      const data = docs.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
      return data
    } catch (err) {
      console.log(err)
    }
  }

  export const getExpiredListings = async (userId) => {
    const todaysDate = dayjs(new Date()).unix()
    const ref = doc(db, 'users', userId)
    const colRef = collection(ref, 'Listings')
    const listings = query(
      colRef,
      where('expirationDateUnix', '<', todaysDate),
      orderBy('expirationDateUnix', 'desc'),
    )
    const querySnapshot = await getDocs(listings)
  
    const data = querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
    return data
  }