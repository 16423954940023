import { logout } from '../data/operations/mutations/user'
const sellerNavItems = [
  { name: 'Home', link: '/', func: null },
  { name: 'Profile', link: (uid) => `/profile/${uid}`, func: null },
  { name: 'Create Listing', link: '/create', func: null },
  // { name: 'Sold Listings', link: '/view/sold', func: null },
  // { name: 'Expired Listings', link: (uid) => `/view/${uid}/expired`, func: null },
]

const buyerNavItems = [
  { name: 'Home', link: '/', func: null },
  { name: 'Purchased Listings', link: '/view/purchased', func: null },
]

const pendingDocsNavItems = [
  // If you want them to have other items, add them here
]

// (1) Accept both the raw `user` from Firebase and the `user` from Firestore
export function getNavItems(user, firebaseUser, doLogout) {
  // (2) If no Firebase user => no nav items
  if (!firebaseUser) {
    return []
  }

  // (3) If we have a Firebase user but user is not loaded yet => minimal nav (just Logout)
  if (!firebaseUser) {
    return [
      { name: 'Logout', link: '/login', func: doLogout },
    ]
  }

  // (4) If user does exist, check status
  if (user?.status === 'PENDINGDOCS') {
    return [
      ...pendingDocsNavItems,
      { name: 'Logout', link: '/', func: doLogout },
    ]
  }

  // (5) If role is SELLER
  if (user?.role === 'SELLER') {
    return [
      ...sellerNavItems.map((item) => ({
        ...item,
        link:
          typeof item.link === 'function'
            ? item.link(user.uid)
            : item.link,
      })),
      { name: 'Logout', link: '/login', func: doLogout },
    ]
  }

  // (6) Default to BUYER
  return [
    ...buyerNavItems?.map((item) => ({
      ...item,
      link:
        typeof item.link === 'function'
          ? item.link(user.uid)
          : item.link,
    })),
    { name: 'Logout', link: '/login', func: doLogout },
  ]
}
