import React, {useState} from 'react'
import { Box, CircularProgress } from '@mui/material'
import ListingsContainer from '../ListingsContainer'
import DashboardProfile from '../DashboardProfile'
import CategoryDropdown from '../CategoryDropdown'
import { useAuth } from '../../context/auth'
import { useGetNonExpiredListings } from '../../data/hooks/listings'

const SellerDashBoard = () => {
  const { user } = useAuth()
  const [selectedCategory, setSelectedCategory] = useState(null)
  const { data: listingData, isLoading } = useGetNonExpiredListings({
    userId: user?.uid,
    category: selectedCategory
  })

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCategory(newValue.value)
    } else {
      setSelectedCategory(null)
    }
  }

  return (
    <Box
      sx={{
        background: '#FFF7E7',
        minHeight: '100vh',
      }}
    >
      <Box sx={{ p: 1 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-evenly',
                mb: 3,
              }}
            >
              <DashboardProfile user={user} />
            </Box>
              <>
                <CategoryDropdown
                  onChange={handleCategoryChange}
                  title="Search"
                  defaultValue={selectedCategory}
                />
                <ListingsContainer
                  listings={listingData}
                  user={user}
                />
              </>
          </>
        )} 
      </Box>
    </Box>
  )
}

export default SellerDashBoard
