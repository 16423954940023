import React, { useEffect, useState } from 'react'
import { getSingleListing } from '../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Box } from '@mui/material'
import ListingDetailCard from '../ListingDetailCard'
import { useGetSingleListing } from '../../data/hooks/listings'

function ListingDetailView() {
  const navigate = useNavigate()
  const { id, userId } = useParams()
  const {data: singleListing} = useGetSingleListing(userId, id)

  return (
    <Box>
      <Box
        sx={{
          ml: 2,
          mt: 2,
        }}
      >
        <Button variant="contained" size="small" onClick={() => navigate(-1)}>
          Back
        </Button>
      </Box>
      <Box
        sx={{
          p: 1,
        }}
      >
        <ListingDetailCard listing={singleListing} />
      </Box>
    </Box>
  )
}

export default ListingDetailView
