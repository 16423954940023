import { Box, useMediaQuery, CircularProgress } from '@mui/material'
import React from 'react'
import ListingCard from '../ListingCard'
import ZeroListingDataCard from '../ZeroListingDataCard'
import ZeroBrowsingListDataCard from '../ZeroBrowsingListDataCard'

function ListingsContainer({ listings, isLoading, browsingPage, user }) {
  const isDesktop = useMediaQuery('(min-width:960px)')

  // 1) If still loading, show spinner
  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    )
  }

  // 2) Once not loading, check if we have any listings
  const hasListings = listings && listings.length > 0

  // 3) If there are no listings
  if (!hasListings) {
    if (browsingPage) {
      return <ZeroBrowsingListDataCard />
    }
    if (user?.role === 'SELLER') {
      return <ZeroListingDataCard />
    }
    return null
  }

  // 4) If we do have listings
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(350px, 1fr))',
        gap: 3,
        ...(isDesktop && {
          gridTemplateColumns: 'repeat(3, minmax(350px, 1fr))',
        }),
        ...(!isDesktop && {
          display: 'flex',
          flexDirection: 'column',
        }),
      }}
    >
      {listings.map((listing) => (
        <ListingCard
          listing={listing}
          user={user}
          key={listing.id}
        />
      ))}
    </Box>
  )
}

export default ListingsContainer
