import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import theme from '../../theme'

function DashboardProfile({ user }) {
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Typography variant="h6" sx={{ mr: 2 }}>
        Welcome {user?.name}
      </Typography>
      <Chip
        sx={{
          fontSize: '.8rem',
          color: 'white',
          backgroundColor: theme.palette.secondary.main,
        }}
        label={user?.role}
      />
    </Box>
  )
}

export default DashboardProfile
