import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  CircularProgress,
} from '@mui/material'
import { useAuth } from '../../context/auth'
import {
  useGetNonExpiredListings,
  useGetSoldListings,
  useGetExpiredListings,
  useDeleteListing,
} from '../../data/hooks/listings'

function formatCategory(str) {
  return str
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b[a-z]/g, (char) => char.toUpperCase())
}

/* ==========================================
   1) TABLE for active (non-expired) listings
========================================== */
function ListingsTable({ listings }) {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left">Expiration</TableCell>
              <TableCell align="center">Operation</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings?.map((listing) => (
              <TableRow key={listing.id} hover role="checkbox" tabIndex={-1}>
                <TableCell align="left">
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {listing.title}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Chip
                    label={formatCategory(listing.category)}
                    size="small"
                    sx={{
                      backgroundColor: '#673ab7',
                      color: 'white',
                      fontSize: '.8rem',
                      borderRadius: '10px',
                    }}
                  />
                </TableCell>
                <TableCell align="left">${listing.price}</TableCell>
                <TableCell align="left">
                  {listing.quantity || 'Not defined'}
                </TableCell>
                <TableCell align="left">
                  {listing.expirationDate || 'No expiration'}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="text"
                    sx={{ color: '#673ab7', minWidth: 0, mr: 1 }}
                  >
                    Edit
                  </Button>
                  <Button variant="text" sx={{ color: '#f44336', minWidth: 0 }}>
                    Delete
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: listing.expired ? '#f0ad4e' : '#5cb85c',
                      ':hover': {
                        backgroundColor: listing.expired ? '#ec971f' : '#4cae4c',
                      },
                    }}
                  >
                    {listing.expired ? 'Inactive' : 'Active'}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

/* ==========================================
   2) "Listings" tab content
========================================== */
function ListingsTab({ listings }) {
  return (
    <>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant="body1">
            Total listings: {listings?.length}
          </Typography>
          <Typography variant="body2">
            Active listings: {listings?.filter((l) => !l.expired)?.length}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#673ab7',
              ':hover': { backgroundColor: '#5e35b1' },
              mr: 1,
            }}
          >
            Add new
          </Button>
          <Button
            variant="outlined"
            sx={{ borderColor: '#673ab7', color: '#673ab7' }}
          >
            Filter
          </Button>
        </Box>
      </Box>

      <Box sx={{ padding: '20px' }}>
        <ListingsTable listings={listings} />
      </Box>
    </>
  )
}

/* ==========================================
   3) TABLE for sold listings
========================================== */
function SoldListingsTable({ soldListings }) {
  if (!soldListings || soldListings.length === 0) {
    return <Typography sx={{ p: 2 }}>No sold listings found.</Typography>
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Buyer Email</TableCell>
              <TableCell align="left">Buyer Phone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {soldListings.map((item) => {
              const listing = item.listing
              return (
                <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                  <TableCell align="left">
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {listing.title}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Chip
                      label={formatCategory(listing.category)}
                      size="small"
                      sx={{
                        backgroundColor: '#673ab7',
                        color: 'white',
                        fontSize: '.8rem',
                        borderRadius: '10px',
                      }}
                    />
                  </TableCell>
                  <TableCell align="left">${listing.price}</TableCell>
                  <TableCell align="left">
                    {item?.buyerContactInfo?.email || 'N/A'}
                  </TableCell>
                  <TableCell align="left">
                    {item?.buyerContactInfo?.phone || 'N/A'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

/* ==========================================
   4) "Sold" tab content
========================================== */
function SoldTab({ soldListings, isLoading }) {
  return (
    <>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Sold Listings
        </Typography>
      </Box>

      <Box sx={{ padding: '20px' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <SoldListingsTable soldListings={soldListings} />
        )}
      </Box>
    </>
  )
}

/* ==========================================
   5) TABLE for expired listings
========================================== */
function ExpiredListingsTable({ listings, handleDelete }) {
  if (!listings || listings.length === 0) {
    return <Typography sx={{ p: 2 }}>No expired listings found.</Typography>
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Quantity</TableCell>
              <TableCell align="left">Expired On</TableCell>
              <TableCell align="center">Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listings.map((listing) => (
              <TableRow key={listing.id} hover role="checkbox" tabIndex={-1}>
                <TableCell align="left">
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {listing.title}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Chip
                    label={formatCategory(listing.category)}
                    size="small"
                    sx={{
                      backgroundColor: '#C62828',
                      color: 'white',
                      fontSize: '.8rem',
                      borderRadius: '10px',
                    }}
                  />
                </TableCell>
                <TableCell align="left">${listing.price}</TableCell>
                <TableCell align="left">
                  {listing.quantity || 'Not defined'}
                </TableCell>
                <TableCell align="left">
                  {listing.expirationDate || 'Unknown'}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="text"
                    sx={{ color: '#f44336', minWidth: 0 }}
                    onClick={() => handleDelete(listing)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

/* ==========================================
   6) "Expired" tab content
========================================== */
function ExpiredTab({ expiredListings, isLoading, onDelete }) {
  return (
    <>
      <Box
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Expired Listings
        </Typography>
      </Box>

      <Box sx={{ padding: '20px' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <ExpiredListingsTable listings={expiredListings} handleDelete={onDelete} />
        )}
      </Box>
    </>
  )
}

/* ==========================================
   7) MAIN SellerProfile component
========================================== */
export default function SellerProfile() {
  const [tabValue, setTabValue] = useState(0)
  const { user } = useAuth()

  // Non-expired listings
  const { data: listings } = useGetNonExpiredListings({ userId: user.uid })

  // Sold listings
  const { data: soldListings, isLoading: soldLoading } = useGetSoldListings(user?.uid)

  // Expired listings (+ delete)
  const { data: expiredListings, isLoading: expiredLoading } = useGetExpiredListings(user?.uid)
  const { mutateAsync: deleteListing } = useDeleteListing()

  // Switch tabs
  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue)
  }

  // Example delete callback for expired listings
  const handleDeleteExpired = async (listingId) => {
    console.log('listingId:', listingId)
    await deleteListing(listingId)
    // Possibly refetch here if needed, or rely on react-query’s invalidation
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f8f8f8', minHeight: '100vh' }}>
      {/* AppBar with tabs */}
      <AppBar position="static" sx={{ backgroundColor: '#673ab7' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {user ? user.name : 'Business Name'}
          </Typography>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label="Listings" />
            <Tab label="Sold" />
            <Tab label="Expired" />
            <Tab label="Admins" />
          </Tabs>
        </Toolbar>
      </AppBar>

      {/* Conditionally render each tab */}
      {tabValue === 0 && <ListingsTab listings={listings} />}
      {tabValue === 1 && <SoldTab soldListings={soldListings} isLoading={soldLoading} />}
      {tabValue === 2 && (
        <ExpiredTab
          expiredListings={expiredListings}
          isLoading={expiredLoading}
          onDelete={handleDeleteExpired}
        />
      )}
      {tabValue === 3 && (
        <Box sx={{ p: 3 }}>
          <Typography variant="h6">Admins Area</Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Add your “Admins” view or content here.
          </Typography>
        </Box>
      )}
    </Box>
  )
}
