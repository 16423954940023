import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'
import {
  query,
  getDocs,
  collection,
  where,
  setDoc,
  doc,
  getDoc,
  collectionGroup,
  orderBy,
} from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { ref, uploadBytesResumable, getStorage } from 'firebase/storage'
import { geocode } from './server'
// import geofire from "geofire-common";
import { updateUser } from './redux/slices/userSlice'
import { store } from './redux/store.js'
import {
auth,
db,
storage,
functions,
} from './firebaseDbConfig' 

const dayjs = require('dayjs')

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const registerWithEmailAndPassword = async (
  name,
  email,
  password,
  address,
  city,
  state,
  zip,
  businessPhone,
  contactPhone,
  status,
  role,
  ) => {
  // geocode(`${address} ${city} ${state} ${zip}`).then(async (response) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password)
      console.log('res:', res)
      const user = res.user
      console.log('user:', user)

      // Prepare user data excluding businessPhone by default
      let userData = {
        uid: user.uid,
        name,
        email,
        // longitude: response.longitude,
        // latitude: response.latitude,
        // number: response.number,
        // street: response.street,
        // city: response.city,
        // state: response.state,
        // stateCode: response.stateCode,
        // postalCode: response.postalCode,
        // countryCode: response.countryCode,
        // confidence: response.confidence,
        contactPhone,
        status,
        role,
      }
      console.log('userData:', userData)

      // Conditionally include businessPhone for SELLER role
      if (role === 'SELLER') {
        userData.businessPhone = businessPhone
      }

      await setDoc(doc(db, 'users', user.uid), userData)
    } catch (err) {
      console.error(err.message)
    }
  // )
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logout = () => {
  signOut(auth)
}

const fetchUser = async (userObj) => {
  try {
    const q = query(collection(db, 'users'), where('uid', '==', userObj?.uid))
    const doc = await getDocs(q)
    const data = doc.docs[0].data()
    return data
  } catch (err) {
    alert('An error occured while fetching user data')
  }
}

const fetchUserById = async (userId) => {
  try {
    const q = query(collection(db, 'users'), where('uid', '==', userId))
    const doc = await getDocs(q)
    const data = doc.docs[0].data()
    return data
  } catch (err) {
    alert('An error occured while fetching user data')
  }
}

const getListingsByUser = async (userId) => {
  const ref = doc(db, 'users', userId)
  const colRef = collection(ref, 'Listings')
  try {
    const q = query(colRef)
    const docs = await getDocs(q)
    // map docs with id
    const data = docs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const getNonExpiredListingsByUser = async (userId) => {
  const todaysDate = dayjs(new Date()).unix()
  const ref = doc(db, 'users', userId)
  const colRef = collection(ref, 'Listings')
  try {
    const q = query(colRef, where('expirationDateUnix', '>=', todaysDate))
    const docs = await getDocs(q)
    // map docs with id
    const data = docs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

const getSingleListing = async (userId, listingId) => {
  const ref = doc(db, 'users', userId)
  const colRef = doc(collection(ref, 'Listings'), listingId)
  try {
    const docSnapshot = await getDoc(colRef)
    if (docSnapshot.exists()) {
      const listingData = docSnapshot.data()
      // Add the document ID to the listing data
      listingData.id = docSnapshot.id
      return listingData
    } else {
      console.log(`No listing found with ID: ${listingId}`)
      return null
    }
  } catch (err) {
    console.log(err)
  }
}

const getAllListingsCollectionGroupQuery = async () => {
  const todaysDate = dayjs(new Date()).unix()
  const listings = query(
    collectionGroup(db, 'Listings'),
    where('expirationDateUnix', '>=', todaysDate),
    orderBy('expirationDateUnix', 'desc'),
  )

  const querySnapshot = await getDocs(listings)

  const data = querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return data
}

const uploadFiles = async (files, userId) => {
  const storageRef = ref(storage, `${userId}/}`)
  const promises = []
  files[0].forEach((file) => {
    const fileRef = ref(storageRef, file.name)
    const task = uploadBytesResumable(fileRef, file)
    promises.push(task)
  })
  try {
    await Promise.all(promises)
    console.log('Documents uploaded successfully')
  } catch (err) {
    console.log(err)
  }
}

const searchFirestoreListingsByCategory = async (category) => {
  const todaysDate = dayjs(new Date()).unix()
  const listings = query(
    collectionGroup(db, 'Listings'),
    where('category', '==', category.value),
    where('expirationDateUnix', '>=', todaysDate),
    orderBy('expirationDateUnix', 'desc'),
  )
  const querySnapshot = await getDocs(listings)

  const data = querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return data
}

const searchUserFirestoreNotExpiredListingsByCategory = async (
  category,
  userId,
) => {
  const todaysDate = dayjs(new Date()).unix()
  const listings = query(
    collectionGroup(db, 'Listings'),
    where('category', '==', category.value),
    where('expirationDateUnix', '>=', todaysDate),
    where('userId', '==', userId),
    orderBy('expirationDateUnix', 'desc'),
  )
  const querySnapshot = await getDocs(listings)

  const data = querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return data
}

const getExpiredListings = async () => {
  const todaysDate = dayjs(new Date()).unix()
  const listings = query(
    collectionGroup(db, 'Listings'),
    where('expirationDateUnix', '<', todaysDate),
    orderBy('expirationDateUnix', 'desc'),
  )
  const querySnapshot = await getDocs(listings)

  const data = querySnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() }
  })
  return data
}

const createListingCheckoutSessionCloudFn = httpsCallable(
  functions,
  'createListingCheckoutSession',
)

const createListingCheckoutSession = async ({
  listingId,
  listingUserId,
  buyerId,
}) => {
  const sessionRes = await createListingCheckoutSessionCloudFn({
    listingId,
    listingUserId,
    buyerId,
  })
  return sessionRes.data.sessionUrl
}

const getPurchasedListingsByUser = async (userId) => {
  const ref = doc(db, 'users', userId)
  const colRef = collection(ref, 'PurchasedListings')
  try {
    const q = query(colRef)
    const docs = await getDocs(q)
    // map docs with id
    const data = docs.docs.map((doc) => {
      return { id: doc.id, ...doc.data() }
    })
    return data
  } catch (err) {
    console.log(err)
  }
}

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  fetchUser,
  getListingsByUser,
  getSingleListing,
  getAllListingsCollectionGroupQuery,
  uploadFiles,
  searchFirestoreListingsByCategory,
  getExpiredListings,
  searchUserFirestoreNotExpiredListingsByCategory,
  getNonExpiredListingsByUser,
  createListingCheckoutSession,
  fetchUserById,
  getPurchasedListingsByUser,
}
