import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { ListItemIcon, ListItemText, Typography } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'

function BulletList({ listOfBullets }) {
  const items = listOfBullets.map((item) => (
    <>
      {item !== '' && (
        <ListItem
          key={`bullet-${item}-${Math.random()}`}
          sx={{
            p: 0,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: '1rem',
            }}
          >
            <CircleIcon
              sx={{
                fontSize: '0.5rem',
              }}
            />
          </ListItemIcon>
          <ListItemText
            secondary={<Typography variant="body1">{item}</Typography>}
          />
        </ListItem>
      )}
    </>
  ))

  return <List>{items}</List>
}

export default BulletList
