import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  CardActions,
  Divider,
  Grid,
} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import CategoryIcon from '@mui/icons-material/Category'
import CircularProgress from '@mui/material/CircularProgress'
import { useAuth } from '../../context/auth'
import { useGetSoldListings } from '../../data/hooks/listings'

function SellerPurchasedListingsContainer() {
  const { user } = useAuth()
  const { data: listings, isLoading } = useGetSoldListings(user?.uid)

  return (
    <Box
      sx={{
        m: 2,
        borderRadius: 1,
      }}
    >
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {listings.map((listing) => (
            <Card
              key={listing.id}
              sx={{ marginBottom: 2, boxShadow: '5px 5px 5px grey' }}
            >
              <CardHeader
                title={listing.listing.title}
                subheader={`Expiry Date: ${listing?.listing?.expirationDate}`}
              />
              <Divider />
              <CardContent>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  <CategoryIcon fontSize="small" /> Category:{' '}
                  {listing?.listing?.category}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Price: ${listing?.listing?.price}
                </Typography>
              </CardContent>
              <Divider />
              <CardActions
                disableSpacing
                sx={{
                  padding: '0 16px 8px 16px',
                }}
              >
                <Typography variant="subtitle2" component="span" gutterBottom>
                  Seller Info:
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <IconButton edge="end" color="secondary">
                      <a
                        href={`mailto:${listing.buyerContactInfo.email}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <EmailIcon />
                        {listing.buyerContactInfo.email}
                      </a>
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <IconButton edge="end" color="secondary">
                      <a
                        href={`tel:${listing.buyerContactInfo.phone}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        <PhoneIcon />
                        {listing.buyerContactInfo.phone}
                      </a>
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          ))}
        </>
      )}
    </Box>
  )
}

export default SellerPurchasedListingsContainer
