import React from 'react'
import { Route } from 'react-router-dom'
import LoginView from '../../feature/LoginView'
import CreateAccountStepper from '../../feature/CreateAccountStepper'
import BuyerDashboard from '../../feature/BuyerDashboard'
import ResetPassword from '../../feature/ResetPassword'
import SellerDashBoard from '../../feature/SellerDashboard'
import CreateListingPage from '../../feature/CreateListingPage'
import EditListing from '../../feature/EditListing'
import ListingDetailView from '../../feature/ListingDetailView'
import DocumentUpload from '../../feature/DocumentUpload'
import BrowsingPage from '../../feature/BrowsingPage'
import ExpiredListingsContainer from '../../feature/ExpiredListingsContainer'
import BuyerPurchasedListingsContainer from '../../feature/BuyerPurchasedListingContainer'
import SellerPurchasedListingsContainer from '../../feature/SellerPurchasedListingsContainer'
import SellerProfile from '../../feature/SellerProfile'
import AboutPage from '../../feature/About'

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route path="/about" element={<AboutPage />} exact strict key="about" />,
  <Route
    path="/browse"
    element={<BrowsingPage />}
    exact
    strict
    key="documents"
  />,
  <Route path="/login" element={<LoginView />} exact strict key="login" />,
  <Route path="/" element={<BuyerDashboard />} exact strict key="home" />,
  <Route
    path="/register"
    element={<CreateAccountStepper />}
    exact
    key="create"
  />,
  <Route path="/reset" element={<ResetPassword />} exact strict key="reset" />,
  <Route
    path="/dashboard-seller"
    element={<SellerDashBoard />}
    exact
    strict
    key="dashboard-seller"
  />,
  <Route
    path="/create"
    element={<CreateListingPage />}
    exact
    strict
    key="createListing"
  />,
  <Route path="/edit/:id" element={<EditListing />} exact strict key="edit" />,
  <Route
    path="/view/:userId/:id"
    element={<ListingDetailView />}
    exact
    strict
    key="listingView"
  />,
  <Route
    path="/upload-docs"
    element={<DocumentUpload />}
    exact
    strict
    key="documents"
  />,
  <Route
    path="/view/:userId/expired"
    element={<ExpiredListingsContainer />}
    exact
    strict
    key="expired"
  />,
  <Route
    path="/view/purchased"
    element={<BuyerPurchasedListingsContainer />}
    exact
    strict
    key="expired"
  />,
  <Route
    path="/view/sold"
    element={<SellerPurchasedListingsContainer />}
    exact
    strict
    key="sold"
  />,
  <Route
    path="/profile/:userId"
    element={<SellerProfile />}
    exact
    strict
    key="profile"
  />,
]
