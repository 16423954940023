// DrawerAppBar.js
import React from 'react'
import { AppBar, Box, Drawer, Toolbar, Typography, IconButton, List, ListItem, ListItemText, ListItemButton, Divider, Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { useNavigate, Routes } from 'react-router-dom'
import { useLogout } from '../../data/hooks/user'
import { getNavItems } from '../../hooks/navItems'
import { useAuth } from '../../context/auth'

function MainLayout(props) {
  const { window, routes } = props
  const { user, firebaseUser } = useAuth()  
  const navigate = useNavigate()
  const { mutateAsync: doLogout } = useLogout()

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const navItems = getNavItems(user, firebaseUser, doLogout)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#5A4AE3', height: '100%' }}>
      <Typography variant="h6" sx={{ my: 1, color: 'white' }}>
        A4A
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <React.Fragment key={item.name}>
            <ListItem disablePadding>
              <ListItemButton
                sx={(theme) => ({
                  textAlign: 'center',
                  '&:hover': {
                    backgroundColor: theme.palette.tertiary.main,
                    boxShadow: '0 4px 7px 3px rgba(0, 0, 0, .2)',
                    transform: 'scale(1.05)',
                  },
                })}
                onClick={() => {
                  if (item.func) {
                    navigate(item.link)
                    item.func()
                  } else {
                    navigate(item.link)
                  }
                }}
              >
                <ListItemText primary={item.name} sx={{ color: 'white' }} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <AppBar component="nav" position="sticky" style={{ zIndex: 1100 }}>
          <Toolbar>
            {/* Drawer toggle for mobile, only if user is valid */}
            {user && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { sm: 'block' } }}
            >
              Alternatives 4 Aging
            </Typography>

            {/* Desktop nav items */}
            {user && (
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {navItems.map((item) => (
                  <Button
                    key={item.name}
                    sx={(theme) => ({
                      color: '#fff',
                      borderRadius: '10px',
                      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
                      margin: '0 8px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        backgroundColor: theme.palette.tertiary.main,
                        boxShadow: '0 4px 7px 3px rgba(0, 0, 0, .2)',
                        transform: 'scale(1.05)',
                      },
                    })}
                    onClick={() => {
                      if (item.func) {
                        item.func()
                        navigate(item.link)
                      } else {
                        navigate(item.link)
                      }
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            )}
          </Toolbar>
        </AppBar>

        {/* Mobile drawer */}
        {user && (
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: 180,
                  backgroundColor: '#5A4AE3',
                  color: 'white',
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        )}
      </Box>
      <Routes>{routes}</Routes>
    </Box>
  )
}

export default MainLayout
