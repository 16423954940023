import React, { useState } from 'react'
import { Box } from '@mui/material'
import DashboardProfile from '../DashboardProfile'
import ListingsContainer from '../ListingsContainer'
import CategoryDropdown from '../CategoryDropdown'
import { useAuth } from '../../context/auth'
import { useGetNonExpiredListings } from '../../data/hooks/listings'
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  const navigate = useNavigate()
  const { user, firebaseUser } = useAuth()
  if (!firebaseUser) {
    navigate('/login')
  }
  const [selectedCategory, setSelectedCategory] = useState(null)

  const { data: listingData, isLoading } = useGetNonExpiredListings({
    category: selectedCategory
  })

  const handleCategoryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCategory(newValue.value)
    } else {
      setSelectedCategory(null)
    }
  }

  return (
    <Box
      sx={{
        p: 1,
        backgroundColor: '#FFF7E7',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          mb: 3,
        }}
      >
        <DashboardProfile user={user} />
      </Box>
      <CategoryDropdown onChange={handleCategoryChange} title="Search" defaultValue={selectedCategory} />
      <ListingsContainer listings={listingData} user={user} isLoading={isLoading} />
    </Box>
  )
}

export default Dashboard
