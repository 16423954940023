// AuthContext.js
import React, { createContext, useContext } from 'react'
import { auth } from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useGetUser } from '../data/hooks/user'

const AuthContext = createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [firebaseUser, loadingAuth] = useAuthState(auth)
  const { data: user, isLoading: loadingUserInfo } = useGetUser(
    firebaseUser?.uid
  )
  const isLoading = loadingAuth || loadingUserInfo

  const value = {
    user,
    firebaseUser,
    isLoading,
  }

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  )
}
