import {
  query,
  getDocs,
  collection,
  where,
} from 'firebase/firestore'
import { db } from '../../../firebaseDbConfig'

export async function getUser(userId) {
  if (!userId) {
    return {}
  }
  try {
    const q = query(collection(db, 'users'), where('uid', '==', userId))
    const doc = await getDocs(q)
    const data = doc.docs[0].data()
    return data
  } catch (err) {
    alert('An error occured while fetching user data')
  }
}